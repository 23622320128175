//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// THIS WAS HERE ORIGIONALLY, KEEP HERE FOR REFERENCE
// // Theme colors
// // Primary
// $primary: if(isDarkMode(), #3699ff, #009ef7);
// $primary-active: if(isDarkMode(), #187de4, #0095e8);
// $primary-light: if(isDarkMode(), #212e48, #f1faff);
// $primary-inverse: #ffffff;

// // Success
// $success: if(isDarkMode(), #0bb783, #50cd89);
// $success-active: if(isDarkMode(), #04aa77, #47be7d);
// $success-light: if(isDarkMode(), #1c3238, #e8fff3);
// $success-inverse: #ffffff;

// // Info
// $info: if(isDarkMode(), #8950fc, #7239ea);
// $info-active: if(isDarkMode(), #7337ee, #5014d0);
// $info-light: if(isDarkMode(), #2f264f, #f8f5ff);
// $info-inverse: #ffffff;

// // Danger
// $danger: if(isDarkMode(), #f64e60, #f1416c);
// $danger-active: if(isDarkMode(), #ee2d41, #d9214e);
// $danger-light: if(isDarkMode(), #3a2434, #fff5f8);
// $danger-inverse: #ffffff;

// // Warning
// $warning: if(isDarkMode(), #ffa800, #ffc700);
// $warning-active: if(isDarkMode(), #ee9d01, #f1bc00);
// $warning-light: if(isDarkMode(), #392f28, #fff8dd);
// $warning-inverse: #ffffff;

// Theme colors
// Primary
$primary: #546a90;
$primary-active: #214378;
// $primary-light: if(isDarkMode(), #212e48, #f5f8fa);
// $primary-light: if(isDarkMode(), #212e48, #f1faff);
$primary-light: if(isDarkMode(), #212e48, rgb(245, 248, 250));
$primary-inverse: #ffffff;

// Secondary

// Success
$success: if(isDarkMode(), #0bb783, #50cd89);
$success-active: if(isDarkMode(), #04aa77, #47be7d);
$success-light: if(isDarkMode(), #1c3238, #e8fff3);
$success-inverse: #ffffff;

// Info
$info: if(isDarkMode(), #546a90, #546a90);
$info-active: if(isDarkMode(), #7337ee, #5014d0);
$info-light: if(isDarkMode(), #2f264f, #f8f5ff);
$info-inverse: #ffffff;

// Danger
$danger: if(isDarkMode(), #f64e60, #f1416c);
$danger-active: if(isDarkMode(), #ee2d41, #d9214e);
$danger-light: if(isDarkMode(), #3a2434, #fff5f8);
$danger-inverse: #ffffff;

// Warning
$warning: if(isDarkMode(), #ffa800, #ffc700);
$warning-active: if(isDarkMode(), #ee9d01, #f1bc00);
$warning-light: if(isDarkMode(), #392f28, #fff8dd);
$warning-inverse: #ffffff;

.svg-home-website-button span {
    margin-right: 0 !important;
}

span.link-primary:hover {
    transition: color 0.2s ease;
}

.dashboard-link {
    transition: 0.2s ease;
    background-color: #fff;
    border-radius: 0.625rem;

    .icon-colorize {
        transition: 0.2s ease;
    }

    &:hover {
        transition: 0.2s ease;
        background-color: #f5f8fa;

        .text-hover-primary {
            color: #546a90 !important;
        }

        .icon-colorize {
            color: #546a90 !important;
        }
    }
}

@media screen and (max-width: 1199px) and (min-width: 992px) {
    body div div div .dashboard-link .symbol {
        display: none !important;
    }
}

@page {
    margin-top: 0mm;
    // margin-bottom: 0mm;
}

// @media screen {
.table-container {
    overflow: auto;
}
// }

@media print {
    .no-print,
    .no-print * {
        display: none !important;
    }

    .print {
        display: block !important;
    }

    .print-space-350 {
        display: block !important;
        height: 350px;
    }

    .print-with-border {
        border: 1px solid #546a90;
    }

    .table-container {
        overflow: visible !important;
    }

    .invoice-page,
    .receipt-page {
        margin-top: -20px !important;
        margin-left: -80px !important;
        margin-right: -80px !important;
    }

    .shared-invoice-page .invoice-page,
    .shared-receipt-page .receipt-page {
        margin-top: 0px !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
    }

    body div div .certificate-container,
    body div div .wallet-card-container {
        overflow: visible !important;
    }

    .transcript-courses-table {
        td {
            &.title {
                min-width: 150px !important;
            }

            &.date {
                min-width: 100px !important;
            }

            &.credits {
                min-width: 50px !important;
            }

            &.status {
                min-width: 70px !important;
            }

            &.doc {
                display: none !important;
            }

            &.action {
                display: none !important;
            }
        }

        th {
            &.doc {
                display: none !important;
            }

            &.action {
                display: none !important;
            }
        }
    }

    .summary-page {
        margin-top: -60px !important;
        margin-right: -110px !important;
        margin-left: -110px !important;
    }

    .transcript-top {
        margin-top: -50px !important;
        margin-right: -80px !important;
        margin-left: -80px !important;
        font-size: 10px !important;
    }

    .transcripts-logo {
        transform: scale(0.7) translateX(-70px);
    }

    .pagebreak {
        page-break-before: always;
        padding-top: 72px;
    }

    .summary-header {
        position: absolute;
        top: 0;
        width: 100%;
        padding-right: 50px;
        padding-top: 40px;

        .passport-photo-box {
            width: 50mm;
            height: 70mm;
            border: 2px solid;
        }
    }

    .summary-user-info-table {
        td {
            min-width: 150px !important;

            &.line1 {
                max-width: 150px !important;
            }
        }
    }

    .summary-educations-table {
        td {
            &.school {
                min-width: 400px !important;
            }

            &.type {
                min-width: 120px !important;
            }

            &.year {
                min-width: 70px !important;
            }
        }
    }

    .summary-employments-table {
        font-size: 8px;
        td {
            &.employment-name {
                min-width: 100px !important;
            }

            &.location {
                min-width: 80px !important;
            }

            &.title {
                min-width: 100px !important;
            }

            &.year {
                min-width: 80px !important;
            }

            &.employer-name {
                min-width: 100px !important;
            }

            &.employer-phone {
                min-width: 80px !important;
            }

            &.employer-email {
                min-width: 150px !important;
            }
        }
    }

    .summary-jurisdictions-table {
        font-size: 8px;

        td {
            &.body {
                min-width: 100px !important;
            }

            &.type {
                min-width: 100px !important;
            }

            &.number {
                min-width: 100px !important;
            }

            &.date {
                min-width: 80px !important;
            }

            &.time {
                min-width: 100px !important;
            }

            &.scope {
                min-width: 200px !important;
            }
        }
    }
}

.print {
    display: none;
}

.print-space-350 {
    display: none;
}

.certificate-license {
    width: 800px;

    .details {
        width: 91%;
    }
}

.wallet-card {
    border: 1px dashed rgba(84, 106, 144, 1);
    height: 54mm;
    width: 171.2mm;

    .wallet-front {
        border-right: 1px dashed rgba(84, 106, 144, 1);

        .signature-line {
            border-top: 1px solid rgba(0, 0, 0, 0.7);
        }
    }

    .wallet-back ul {
        padding-left: 1rem !important;
    }
}

body .aside.aside-dark .btn-custom:hover:not(.btn-active).btn-custom-me:hover {
    background-color: rgba(107, 109, 119, 0.35) !important;
}

.facility-table {
    td {
        display: table-cell;
        vertical-align: middle;
    }

    .phone {
        min-width: 120px;
        max-width: 200px;
    }

    .name {
        max-width: 300px;
        min-width: 200px;
        text-align: start;
    }

    .city {
        min-width: 120px;
        max-width: 200px;
    }

    .conact {
        min-width: 120px;
        max-width: 200px;
    }

    .old-name {
        max-width: 300px;
        min-width: 200px;
    }

    .action {
        max-width: 120px;
    }
}

.receipts-table {
    td {
        display: table-cell;
        vertical-align: middle;

        &.date {
            min-width: 130px;
        }

        &.type {
            min-width: 100px;
        }

        &.amount {
            min-width: 120px;
        }

        &.action {
            min-width: 120px;
        }
    }
}

.invoices-table {
    td {
        display: table-cell;
        vertical-align: middle;

        &.date {
            min-width: 130px;
        }

        &.type {
            min-width: 140px;
        }

        &.amount {
            min-width: 160px;
        }

        &.outstanding {
            min-width: 180px;
        }

        &.action {
            min-width: 140px;
        }
    }
}

.districts-table {
    td {
        display: table-cell;
        vertical-align: middle;

        &.name {
            min-width: 200px;
        }

        &.office {
            min-width: 200px;
        }
    }
}

.subcategories-table {
    td {
        display: table-cell;
        vertical-align: middle;

        &.category {
            min-width: 150px;
        }

        &.subcategory {
            min-width: 500px;
        }
    }
}

.species-table {
    td {
        display: table-cell;
        vertical-align: middle;

        &.category {
            min-width: 150px;
        }

        &.species {
            min-width: 500px;
        }
    }
}

.subtypes-table {
    td {
        display: table-cell;
        vertical-align: middle;

        &.category {
            min-width: 150px;
        }

        &.subtype {
            min-width: 500px;
        }
    }
}

.invoice-items-table,
.receipt-items-table {
    td {
        display: table-cell;
        vertical-align: middle;

        &.item {
            min-width: 250px;
        }

        &.amount {
            min-width: 120px;
        }

        &.qty {
            min-width: 100px;
        }

        &.sub {
            min-width: 120px;
        }
    }
}

.invoice-summary-table,
.receipt-summary-table {
    td {
        display: table-cell;
        vertical-align: middle;

        &.title {
            min-width: 300px;
        }

        &.value {
            width: 200px;
        }
    }
}

.transcript-courses-table {
    td {
        display: table-cell;
        vertical-align: middle;

        &.title {
            min-width: 170px;
        }

        &.date {
            min-width: 130px;
        }

        &.credits {
            min-width: 140px;
        }

        &.status {
            min-width: 150px;
        }

        &.doc {
            min-width: 200px;
        }

        &.action {
            min-width: 100px;
        }
    }
}

.transcript-courses-renewal-table {
    td {
        display: table-cell;
        vertical-align: middle;

        &.title {
            min-width: 170px;
        }

        &.date {
            min-width: 130px;
        }

        &.credits {
            min-width: 140px;
        }

        &.status {
            min-width: 150px;
        }

        &.doc {
            min-width: 200px;
        }

        &.action {
            min-width: 100px;
        }
    }
}

.applications-table {
    td {
        display: table-cell;
        vertical-align: middle;

        &.date {
            min-width: 130px;
        }

        &.type {
            min-width: 200px;
        }

        &.status {
            min-width: 120px;
        }

        &.details {
            min-width: 150px;
        }

        &.action {
            min-width: 200px;
        }
    }
}

.assessments-table {
    td {
        display: table-cell;
        vertical-align: middle;

        &.date {
            min-width: 130px;
        }

        &.completed {
            min-width: 150px;
        }

        &.action {
            min-width: 200px;
        }
    }
}

.educations-table {
    td {
        display: table-cell;
        vertical-align: middle;

        &.type {
            min-width: 130px;
        }

        &.school {
            min-width: 250px;
        }

        &.year {
            min-width: 90px;
        }

        &.specialty {
            min-width: 220px;
        }

        &.action {
            min-width: 150px;
        }
    }
}

.jurisdictions-table {
    td {
        display: table-cell;
        vertical-align: middle;

        &.body {
            min-width: 130px;
        }

        &.type {
            min-width: 250px;
        }

        &.number {
            min-width: 200px;
        }

        &.from {
            min-width: 90px;
        }

        &.to {
            min-width: 90px;
        }

        &.action {
            min-width: 150px;
        }
    }
}

.employments-table {
    td {
        display: table-cell;
        vertical-align: middle;

        &.name {
            min-width: 130px;
        }

        &.job {
            min-width: 250px;
        }

        &.location {
            min-width: 250px;
        }

        &.from {
            min-width: 90px;
        }

        &.to {
            min-width: 90px;
        }

        &.action {
            min-width: 150px;
        }
    }
}

.status-details-table {
    td {
        display: table-cell;
        vertical-align: middle;

        &.name {
            min-width: 200px;
        }

        &.value {
            min-width: 350px;
        }
    }
}

.status-invoices-table {
    td {
        display: table-cell;
        vertical-align: middle;

        &.name {
            min-width: 150px;
        }

        &.total {
            min-width: 100px;
        }

        &.paid {
            min-width: 70px;
        }

        &.action {
            min-width: 150px;
        }
    }
}

.status-requirements-table {
    td {
        display: table-cell;
        vertical-align: middle;

        &.name {
            min-width: 350px;
        }

        &.completed {
            min-width: 100px;
        }
    }
}

.summary-user-info-table.table.table-bordered {
    td {
        display: table-cell;
        vertical-align: middle;
        text-align: start;
        border-width: 2px !important;
        min-width: 300px;

        &.line1 {
            white-space: nowrap;
            overflow-y: visible;
            max-width: 300px;
        }
    }

    tbody tr:last-child td {
        border-bottom: 2px solid #eff2f5 !important;
    }

    tr td:last-child {
        border-right-width: 0 !important;
    }

    tr {
        border-right-width: 2px !important;
    }

    .line1 {
        border-right-width: 0 !important;
    }
}

.summary-educations-table.table.table-bordered {
    td {
        display: table-cell;
        vertical-align: middle;
        text-align: start;
        border-width: 2px !important;

        &.school {
            min-width: 500px;
        }

        &.type {
            min-width: 250px;
        }

        &.year {
            min-width: 100px;
        }
    }

    tbody tr:last-child td {
        border-bottom: 2px solid #eff2f5 !important;
    }

    tr td,
    tr th {
        border: 2px solid #eff2f5 !important;
    }
}

.summary-employments-table.table.table-bordered {
    td {
        display: table-cell;
        vertical-align: middle;
        text-align: start;
        border-width: 2px !important;

        &.employment-name {
            min-width: 150px;
        }

        &.location {
            min-width: 100px;
        }

        &.title {
            min-width: 150px;
        }

        &.year {
            min-width: 120px;
        }

        &.employer-name {
            min-width: 150px;
        }

        &.employer-phone {
            min-width: 130px;
        }

        &.employer-email {
            min-width: 120px;
        }

        &.description {
            min-width: 200px;
        }
    }

    tbody tr:last-child td {
        border-bottom: 2px solid #eff2f5 !important;
    }

    tr td,
    tr th {
        border: 2px solid #eff2f5 !important;
    }
}

.summary-jurisdictions-table.table.table-bordered {
    td {
        display: table-cell;
        vertical-align: middle;
        text-align: start;
        border-width: 2px !important;

        &.body {
            min-width: 150px;
        }

        &.type {
            min-width: 100px;
        }

        &.number {
            min-width: 150px;
        }

        &.date {
            min-width: 120px;
        }

        &.time {
            min-width: 150px;
        }

        &.scope {
            min-width: 200px;
        }
    }

    tbody tr:last-child td {
        border-bottom: 2px solid #eff2f5 !important;
    }

    tr td,
    tr th {
        border: 2px solid #eff2f5 !important;
    }
}

.intitail-values-review-table {
    td.label {
        display: table-cell;
        vertical-align: middle;
        text-align: start;
        padding-left: 20px !important;
        white-space: nowrap;
    }

    td.value {
        display: table-cell;
        vertical-align: middle;
        text-align: start;
        min-width: 300px;
        padding-right: 20px !important;
    }
}

.facility-card .facility-card-inner {
    position: relative;
    padding: 1.5rem 0 1.5rem 2.5rem;

    &::before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 4px;
        border-radius: 6px;
        background-color: $primary;
    }
}

.note-card .note-card-inner {
    position: relative;
    padding: 1.5rem 0 1.5rem 2.5rem;

    &::before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 4px;
        border-radius: 6px;
        background-color: $primary;
    }
}

.transcripts-logo {
    margin-left: -17px;
}

.wizard-stepper-progress {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 30px;

    .step {
        color: #adb5c4;
        font-weight: 600;
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-top: 25px;
        margin-bottom: 25px;

        .step-number {
            display: flex;
            justify-content: center;
            align-items: center;
            border: 2px solid #b8bfcb;
            border-radius: 50%;
            height: 40px;
            width: 40px;
            margin-right: 12px;
            margin-left: 12px;

            &.completed {
                border: 2px solid $primary;
                background: $primary;
                color: #fff;
            }

            &.current {
                border: 2px solid $primary;
                color: $primary;
            }
        }

        .step-title {
            position: absolute;
            bottom: 50px;

            &.completed {
                color: $primary;
            }

            &.current {
                color: $primary;
            }
        }
    }

    .step-divider {
        flex-grow: 1;
        min-width: 30px;
        border-bottom: 2px dashed #adb5c4;

        &.completed {
            border-bottom: 2px solid $primary;
        }

        &.current {
            border-bottom: 2px dashed $primary;
        }
    }
}

.answer-space-500 {
    width: 500px;
    border-bottom: 1px solid;
    display: inline-block;
    margin-left: 2px;
    padding-left: 5px;
}

.answer-space-400 {
    width: 400px;
    border-bottom: 1px solid;
    display: inline-block;
    margin-left: 2px;
    padding-left: 5px;
}

.answer-space-200 {
    width: 200px;
    border-bottom: 1px solid;
    display: inline-block;
    margin-left: 2px;
    padding-left: 5px;
}

.answer-space-30 {
    width: 30px;
    border-bottom: 1px solid;
    display: inline-block;
    margin-left: 2px;
    padding-left: 5px;
}

.blank-answer-space-400 {
    width: 400px;
    border-bottom: 1px solid;
    display: inline-block;
    margin-left: 2px;
    padding-left: 5px;
    transform: translateY(3px);
}

.blank-answer-space-200 {
    width: 200px;
    border-bottom: 1px solid;
    display: inline-block;
    margin-left: 2px;
    padding-left: 5px;
    transform: translateY(3px);
}

.blank-answer-space-30 {
    width: 30px;
    border-bottom: 1px solid;
    display: inline-block;
    margin-left: 2px;
    padding-left: 5px;
    transform: translateY(3px);
}

.bold-ol > li::marker {
    font-weight: 600;
}

.table tr:last-child,
.table th:last-child,
.table td:last-child {
    padding-right: 0.75rem !important;
}

.table tr:first-child,
.table th:first-child,
.table td:first-child {
    padding-left: 0.75rem !important;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
    --bs-table-accent-bg: rgba(245, 248, 250, 1) !important;
}

.table.table-bordered {
    border-width: 1px !important;

    tbody tr:last-child td {
        border-bottom: 1px solid #eff2f5 !important;
    }

    tr td,
    tr th {
        border: 1px solid #eff2f5 !important;
    }
}

.auth-logo {
    height: 80px;
}

@media (max-width: 425px) {
    .auth-logo {
        height: 60px;
    }
}

@media (max-width: 600px) {
    body div div div {
        .quick-links {
            display: none !important;
        }

        .username-display {
            display: none !important;
        }
    }
}

@media (max-width: 991px) {
    body div .toolbar-custom {
        display: none !important;
    }

    body div .header-custom {
        box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.05) !important;
    }

    body div .header-wrapper-custom {
        padding-top: 55px !important;
    }
}

.note-number {
    position: absolute;
    font-size: 7px;
}

.password-input-container {
    position: relative;
    display: flex;
    align-items: center;

    .password-toggle {
        position: absolute;
        right: 0;
        padding: 10px;
    }
}
.payment-options-container {
    #kt_accordion_1 {
        width: 50%;
        margin-right: 10px;

        .accordion-body {
            padding: 15px;
        }
    }
    #kt_accordion_2 {
        width: 50%;
        margin-left: 10px;

        .accordion-body {
            padding: 40px;
        }
    }
}

@media (max-width: 991px) {
    .payment-options-container {
        flex-direction: column;

        #kt_accordion_1 {
            width: 100%;
            margin-right: 0;
            margin-bottom: 20px;
        }
        #kt_accordion_2 {
            width: 100%;
            margin-left: 0;
        }
    }

    body div div .payment-logo {
        height: 50px !important;
    }

    .transcripts-info-container {
        flex-direction: column;
    }

    body div div .transcripts-logo {
        height: 40px !important;
        margin-left: -7px !important;
    }

    .note-number {
        display: none !important;
    }
}

@media (max-width: 700px) {
    .payment-options-container {
        #kt_accordion_1 .accordion-body {
            padding: 10px;
        }

        #kt_accordion_2 .accordion-body {
            padding: 20px;
        }
    }
}

.shared-invoice-page,
.shared-receipt-page {
    display: flex;
    justify-content: center;

    .invoice-page {
        flex-grow: 1;
        padding: 50px 30px;
        max-width: 1140px;
    }

    .receipt-page {
        flex-grow: 1;
        padding: 50px 30px;
        max-width: 1140px;
    }
}

.share-payment-success-page {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}
